<template>
  <div>
    <slot
      name="enabled"
      :onEnabledChange="onEnabledChange"
      :value="enabled"
    ></slot>

    <!-- 單選 -->
    <v-text-field
      v-if="!multiple"
      outlined
      dense
      readonly
      :value="dataText"
      clearable
      @click:clear="clear"
    >
      <template v-slot:append-outer>
        <v-icon
          @click="openSelector"
          class="pt-1"
        >
          fa fa-search
        </v-icon>
      </template>
    </v-text-field>

    <!-- 多選 -->
    <div v-if="multiple" class="px-2 pt-2">
      <div class="d-flex">
        <v-icon
          @click="openSelector"
          class="mr-4"
        >
          fa fa-search
        </v-icon>

        <v-icon
          v-if="filterValue"
          @click="clear"
        >
          fa fa-times
        </v-icon>

        <div v-if="multipleEmptyText">{{multipleEmptyText}}</div>
      </div>

      <div class="mt-4">
        <v-chip
          v-for="(item, itemIndex) in filterValue"
          :key="itemIndex"
          color="primary lighten-2"
          label
          class="mr-2 mb-2"
        >
          <template v-slot:default>
            <span class="mr-2">{{getMultipleModelItemDataText(item)}}</span>
            <v-icon @click="removeItem(itemIndex)" class="cursor-pointer">
              fa fa-times
            </v-icon>
          </template>
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import filter from '@/components/list/listSearchAndFilter/filter.js'
export default {
  mixins: [filter],
  methods: {
    openSelector() {
      this.config.selector({
        max: this.max,
        multiple: this.multiple,
        // usedList: this.data,
        applyCallback: this.applyCallback,
      })
    },
    applyCallback(data) {
      if(typeof this.config.selectorApplyCallback === 'function') {
        this.filterValue = this.config.selectorApplyCallback(data)
        return
      }
      this.filterValue = data
    },
    clear() {
      this.filterValue = null
    },
    getMultipleModelItemDataText(item) {
      if(typeof this.config.dataText == 'function') {
        return this.config.dataText(window.eagleLodash.cloneDeep(item))
      }

      return window.eagleLodash.get(item, this.config.dataText)
    },
    removeItem(itemIndex) {
      if(!Array.isArray(this.filterValue)) return
      this.$delete(this.filterValue, itemIndex)
    },
  },
  computed: {
    multipleEmptyText() {
      const multipleEmptyText = this.$t('list.filter.selector.multiple_mode.item_empty')
      if(!Array.isArray(this.filterValue)) return multipleEmptyText
      if(this.filterValue.length == 0) return multipleEmptyText
      return null
    },
    max() {
      if(this.multiple == false) return 1
      if(this.config.max) return parseInt(this.config.max)
      return 5
    },
    multiple() {
      return this.config.multiple === true
    },
    dataText() {
      if(!this.filterValue) return null
      if(typeof this.config.dataText == 'function') {
        return this.config.dataText(window.eagleLodash.cloneDeep(this.filterValue))
      }

      if(this.multiple && Array.isArray(this.filterValue)) {
        return this.filterValue.map(item => {
          return window.eagleLodash.get(item, this.config.dataText)
        })
      }
      return window.eagleLodash.get(this.filterValue, this.config.dataText)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>